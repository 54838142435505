import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import Section from '../../components/Section';
import Grid from '../../components/Styles/Grid';
import Box from '../../components/Styles/Box';
import Heading from '../../components/Styles/Heading';
import Text from '../../components/Styles/Text';
import CardBasic from '../../components/CardBasic';
import SingleLineTextWithIcon from '../../components/SingleLineTextWithIcon';
import PenIcon from '../../components/Icons/Fa/Thin/PenIcon';
import CodeIcon from '../../components/Icons/Fa/Thin/CodeIcon';
import codeLines from '../../images/code-lines.svg';
import UserIcon from '../../components/Icons/Fa/Thin/UserIcon';

const WebDevelopmentPage = (props) => {
  return (
    <Layout>
      <SEO title="Web Development" />
      <PageHeaderStyled
        heading="Custom Web Development"
        subHeading="Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet id officia perspiciatis architecto sint nemo nobis accusantium cumque ratione. Quisquam beatae corporis est vero debitis?"
        buttonText="Request a Free Quote"
        buttonLink="/contact/"
      />
      <PageNavigation>
        <Grid
          as="nav"
          gridTemplateColumns="repeat(7, minmax(200px, 1fr))"
          gridGap={2}
        >
          <SingleLineTextWithIcon icon={<PenIcon />} text="Web Design" />
          <SingleLineTextWithIcon icon={<CodeIcon />} text="Web Development" />
          <SingleLineTextWithIcon icon={<CodeIcon />} text="Website Packages" />
          <SingleLineTextWithIcon
            icon={<CodeIcon />}
            text="White Label Websites"
          />
        </Grid>
      </PageNavigation>
      <Section>
        <Heading>Web Design</Heading>
        <Text>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
          aliquam laboriosam at ullam ea dolorum voluptate debitis illum tempora
          corrupti modi quisquam.
        </Text>
        <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap={[4, 4]}>
          <Box pr={6}>
            <Heading as="h3">UI/UX design</Heading>
            <Text>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
              aliquam laboriosam at ullam ea dolorum voluptate debitis illum
              tempora corrupti modi quisquam aut accusantium inventore libero
              deleniti saepe tenetur veritatis asperiores voluptatum, nemo
              maiores sunt maxime? Explicabo atque aliquam dolorem, in odit non
              accusamus nobis enim ab debitis, molestiae soluta! Similique,
              voluptatibus! Quia, facilis.
            </Text>
          </Box>
          <Box pr={6}>
            <Heading as="h3">UI/UX design</Heading>
            <Text>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
              aliquam laboriosam at ullam ea dolorum voluptate debitis illum
              tempora corrupti modi quisquam aut accusantium inventore libero
              deleniti saepe tenetur veritatis asperiores voluptatum, nemo
              maiores sunt maxime? Explicabo atque aliquam dolorem, in odit non
              accusamus nobis enim ab debitis, molestiae soluta! Similique,
              voluptatibus! Quia, facilis.
            </Text>
          </Box>
        </Grid>
        <Grid gridTemplateColumns={['1fr', '1fr 1fr 1fr']} gridGap={[4, 4]}>
          <CardBasic
            icon={<UserIcon />}
            heading="Service"
            description="This is a card, with a quick description of the service."
            boxShadow={true}
          />
          <CardBasic
            icon={<UserIcon />}
            heading="Service"
            description="This is a card, with a quick description of the service."
            boxShadow={true}
          />
          <CardBasic
            icon={<UserIcon />}
            heading="Service"
            description="This is a card, with a quick description of the service."
            boxShadow={true}
          />
        </Grid>
      </Section>
      <SectionGray>
        <Heading>Custom Web Development</Heading>
        <Text>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
          aliquam laboriosam at ullam ea dolorum voluptate debitis illum tempora
          corrupti modi quisquam aut accusantium inventore libero deleniti saepe
          tenetur veritatis asperiores voluptatum, nemo maiores sunt maxime?
          Explicabo atque aliquam dolorem, in odit non accusamus nobis enim ab
          debitis, molestiae soluta! Similique, voluptatibus! Quia, facilis.
          Officia fugit eos quo deleniti, doloremque ipsa maxime perspiciatis
          esse voluptatibus at libero doloribus?
        </Text>
        <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap={[4, 4]}>
          <Box>
            <Heading as="h3">UI/UX design</Heading>
            <Text>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
              aliquam laboriosam at ullam ea dolorum voluptate debitis illum
              tempora corrupti modi quisquam aut accusantium inventore libero
              deleniti saepe tenetur veritatis asperiores voluptatum, nemo
              maiores sunt maxime? Explicabo atque aliquam dolorem, in odit non
              accusamus nobis enim ab debitis, molestiae soluta! Similique,
              voluptatibus! Quia, facilis.
            </Text>
          </Box>
          <Box>
            <Heading as="h3">UI/UX design</Heading>
            <Text>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
              aliquam laboriosam at ullam ea dolorum voluptate debitis illum
              tempora corrupti modi quisquam aut accusantium inventore libero
              deleniti saepe tenetur veritatis asperiores voluptatum, nemo
              maiores sunt maxime? Explicabo atque aliquam dolorem, in odit non
              accusamus nobis enim ab debitis, molestiae soluta! Similique,
              voluptatibus! Quia, facilis.
            </Text>
          </Box>
        </Grid>
      </SectionGray>
      <Section>
        <Heading>Website Packages</Heading>
        <Text>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
          aliquam laboriosam at ullam ea dolorum voluptate debitis illum tempora
          corrupti modi quisquam aut accusantium inventore libero deleniti saepe
          tenetur veritatis asperiores voluptatum, nemo maiores sunt maxime?
          Explicabo atque aliquam dolorem, in odit non accusamus nobis enim ab
          debitis, molestiae soluta! Similique, voluptatibus! Quia, facilis.
          Officia fugit eos quo deleniti, doloremque ipsa maxime perspiciatis
          esse voluptatibus at libero doloribus?
        </Text>
      </Section>
    </Layout>
  );
};

const SectionGray = styled(Section)`
  background: var(--lightGray);
  /* background: var(--lightGray) url(${codeLines}) no-repeat right -500px top; */
`;

const PageHeaderStyled = styled(PageHeader)`
  background: url(${codeLines}) no-repeat right -100px top;
  min-height: 35vh;
`;

const PageNavigation = styled(Section)`
  padding: 1em 0;
  background: var(--darkBlue);
  color: white;
`;

export default WebDevelopmentPage;
